import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'

export default function NardHome() {
  const views = [
    {
      id: 0,
      title: 'Industry-Tailored Solutions',
      description:
        'We provide basic tech solutions to easily kick start your business with us. Also, if you need any special function to match your business needs, do let us know!',
      icon: '/assets/NardBiz/first.svg',
      mainImage: '/assets/NardBiz/1.png',
    },
    {
      id: 1,
      title: 'Analytics Insights for Growth',
      description:
        'Offers valuable analytics insights to business owners, enabling data-driven decision-making and facilitating business growth strategies.',
      icon: '/assets/NardBiz/second.svg',
      mainImage: '/assets/NardBiz/2.png',
    },
    {
      id: 2,
      title: 'Streamlined Management',
      description:
        'Simplified Posting & Management for Services, Courses, and Menus, Ensuring Smooth Operations & Customer Satisfaction',
      icon: '/assets/NardBiz/third.svg',
      mainImage: '/assets/NardBiz/3.png',
    },
    {
      id: 3,
      title: 'Secure Payment Processing',
      description:
        'Offers secure payment processing, instilling confidence in both business owners and customers by ensuring the safety of transactions within the app.',
      icon: '/assets/NardBiz/fourth.svg',
      mainImage: '/assets/NardBiz/4.png',
    },
    {
      id: 4,
      title: 'Dynamic Customer Communication',
      description:
        'Facilitates direct communication between businesses and customers, enhancing engagement and fostering stronger relationships.',
      icon: '/assets/NardBiz/five.svg',
      mainImage: '/assets/NardBiz/5.png',
    },
    {
      id: 5,
      title: 'Community Forum',
      description:
        'An inclusive community forum for app users to discuss, share insights, and donate products, fostering collaboration and support.',
      icon: '/assets/NardBiz/six.svg',
      mainImage: '/assets/NardBiz/6.png',
    },
  ]

  const [selected, setSelected] = useState(views[0])
  const [animate, setAnimate] = useState(true)

  const handleHover = (value) => {
    setAnimate(false)
    setTimeout(() => {
      setSelected(value)
      setAnimate(true)
    }, 500)
  }

  const handleMouseLeave = () => {
    setAnimate(false)
    setTimeout(() => {
      setSelected(views[0])
      setAnimate(true)
    }, 500)
  }

  return (
    <Box
      sx={{
        paddingTop: '9rem',
        marginLeft: { xs: '0.3rem', md: '3rem' },
        marginRight: { xs: '0.3rem', md: '1rem', xl: '3rem' },
      }}
      className="nard-homes-biz"
      id="nardhomebiz"
    >
      <h4 className="grey-text-200 text-center">
        Simplifying Home-Based Businesses
      </h4>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '1rem 0',
        }}
      >
        <img
          src="/assets/NardBiz/bizlogo.svg"
          alt=""
          style={{ maxWidth: '75px' }}
        />
        <h2
          style={{ textAlign: 'center', marginLeft: '1rem' }}
          className="font-size-45"
        >
          NardHomeBiz
        </h2>
      </Box>
      <h6
        className="grey-text-100 font-size-20"
        style={{ textAlign: 'center' }}
      >
        Realize Your Entrepreneurial Vision
      </h6>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: { xs: '100%', sm: '70%' },
          margin: 'auto',
        }}
      >
        <p
          style={{ textAlign: 'center', margin: '1rem 0' }}
          className="grey-text-100 font-size-15"
        >
          Welcome to NardHomeBiz: Empowering Home-Based Entrepreneurs! A
          community-driven app by Nard Foundation, a Social Enterprise. Join us
          in supporting families in need to kickstart their home business
          dreams. For ambitious entrepreneurs, our platform offers tailored
          solutions for growth and success. Together, let's build thriving home
          businesses and strong communities.
        </p>
      </Box>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0.3rem 0 1rem 0',
        }}
      >
        <img src="/assets/NardBiz/GooglePlay.svg" alt="" />
        <img
          src="/assets/NardBiz/PlayStore.svg"
          alt=""
          style={{ marginLeft: '0.5rem' }}
        />
      </Box> */}

      <Grid
        container
        style={{ marginTop: '2.5rem', justifyContent: 'center' }}
        sx={{ padding: { xs: '0 0.3rem', md: '0' } }}
      >
        <Grid
          item
          xs={11}
          md={3.5}
          xl={4.5}
          sx={{
            fontSize: { lg: '17px' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {views.slice(0, 3).map((value) => (
            <Box
              className={
                value.id === selected.id
                  ? 'nard-list-item-hover'
                  : 'nard-list-item'
              }
              sx={{
                margin: '1rem 0',
                display: 'flex',
                width: { xl: '70%', lg: '80%', md: '100%' },
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                padding: '1rem',
                gap: '1rem',
                textAlign: 'right',
              }}
              key={value.id}
              onMouseOver={() => handleHover(value)}
              onMouseLeave={handleMouseLeave}
            >
              <Box
                sx={{
                  marginLeft: '1rem',
                  textAlign: 'right',
                }}
              >
                <span className="font-weight-600">{value.title}</span>
                <p className="grey-text-100 font-size-15">
                  {value.description}
                </p>
              </Box>
              <img src={value.icon} alt="" />
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={5} xl={3}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box>
              <img
                src={selected.mainImage}
                alt=""
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                className={animate ? 'image-animate' : 'image-none'}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={11}
          md={3.5}
          xl={4.5}
          sx={{
            fontSize: { lg: '17px' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {views.slice(3, 6).map((value) => (
            <Box
              className={
                value.id === selected.id
                  ? 'nard-list-item-hover'
                  : 'nard-list-item'
              }
              sx={{
                margin: '1rem 0',
                width: { xl: '70%', lg: '80%', md: '100%' },

                display: 'flex',
                padding: '1rem',
              }}
              key={value.id}
              onMouseOver={() => handleHover(value)}
              onMouseLeave={handleMouseLeave}
            >
              <img src={value.icon} alt="" />
              <Box sx={{ marginLeft: '1rem' }}>
                <span className="font-weight-600">{value.title}</span>
                <p className="grey-text-100 font-size-15">
                  {value.description}
                </p>
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}
