import * as React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const drawerWidth = 240
const navItems = [
  { name: 'Home', route: '/#home' },
  { name: 'Why us', route: '/#whyus' },
  { name: 'NardHomeBiz', route: '/#nardhomebiz' },
  { name: 'NardFxPro', route: '/#nardfxpro' },
  { name: 'NardCreation', route: '/#nardcreation' },
  { name: 'Testimonials', route: '/#testimonials' },
]

function DrawerAppBar(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  function goTop(route) {
    if (route === '/#home') {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} id="home">
      <Typography variant="h6" sx={{ my: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src="/assets/company-logo.svg"
            alt=""
            className="company-logo"
            style={{ maxWidth: '50px', marginBottom: '2rem' }}
          />
          <h5 variant="h4" component="h1" style={{ fontFamily: 'CustomFont' }}>
            The Nard & Co. Holdings Pte. Ltd.
          </h5>
        </Box>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <a
            href={item.route}
            key={item.route}
            style={{ textDecoration: 'none' }}
          >
            <ListItem disablePadding>
              <ListItemButton
                sx={{ textAlign: 'center', fontFamily: 'CustomFont' }}
                onClick={() => goTop(item.route)}
              >
                <ListItemText
                  sx={{
                    fontFamily: 'CustomFont',
                    maskType: { xs: 'none', sm: 'none', md: 'block' },
                  }}
                  className="font-sidebar"
                  style={{ textAlign: 'center', fontFamily: 'CustomFont' }}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          </a>
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      className="navbar"
    >
      <CssBaseline />
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          boxShadow: 'none',
          padding: '1rem 0 0 1.5rem',
          background: '#e2e2e25e',
          backdropFilter: 'blur(5px)',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { md: 'block', lg: 'none' },
              color: 'black', // Set hamburger icon color to black
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src="/assets/company-logo.svg"
                alt=""
                className="company-logo"
                style={{
                  maxWidth: '50px',
                }}
              />
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  marginLeft: '1rem',
                  fontFamily: 'CustomFont',
                  display: { xs: 'none', sm: 'none', md: 'block' }, // Hide the title text on small and medium screens
                }}
              >
                The Nard & Co. Holdings Pte. Ltd.
              </Typography>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' }, // Hide nav items on medium screens
              }}
            >
              {navItems.map((item) => (
                <a href={item.route} key={item.route}>
                  <Button
                    sx={{
                      color: '#232323',
                      fontFamily: 'CustomFont',
                    }}
                    onClick={() => goTop(item.route)}
                  >
                    <span
                      className="text-transform font-weight-600 font-size-15"
                      style={{ margin: '0 0.7rem' }}
                    >
                      {item.name}
                    </span>
                  </Button>
                </a>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <p
            style={{
              fontFamily: 'CustomFont',
            }}
          >
            {drawer}
          </p>
        </Drawer>
      </nav>
    </Box>
  )
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
}

export default DrawerAppBar
