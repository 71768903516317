import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

export default function index() {
  return (
    <Box sx={{ paddingTop: '10rem' }} id="whyus">
      <h6
        className="grey-text-100 font-size-20"
        style={{ textAlign: 'center' }}
      >
        Overview
      </h6>
      {/* <Grid container sx={{ height: "130px" }}>
        <Grid item xs-ml={0} xs={12} md={8} lg={9.5}> */}
      <h2 style={{ textAlign: 'center' }} className="font-size-45">
        Why Choose Us
      </h2>
      {/* </Grid> */}
      {/* <Grid item xs={0} md={4} lg={2.5}> */}
      <Box pb={5} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <img
          src="/assets/pattren.svg"
          alt=""
          style={{
            width: '153px',
            height: '166px',
            position: 'absolute',
            right: '120px',
          }}
        />
      </Box>
      {/* </Grid> */}
      {/* </Grid> */}
      <Box
        className="whyus-text"
        sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: 'center' }}
      >
        <Typography
          variant="body2"
          sx={{
            color: '#8396AC',
            textAlign: 'center',
            width: { xs: '100%', sm: '70%' },
            fontFamily: 'CustomFont',
          }}
          className="font-size-15"
        >
          At The Nard & Co. Holdings Pte. Ltd., we're committed to drive your
          engagement experience{' '}
          <span style={{ fontWeight: 'bold' }}>"Above and Beyond"</span> the
          Rainbow. Making sure our NardTech fulfilling every needs of yours are
          the primary aim of our company.
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="space-evenly"
        mb={5}
        sx={{ marginTop: '40px' }}
      >
        <Grid item xs={12} md={6} lg={3}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src="/assets/analytics.svg"
                alt=""
                width={85}
                height={77}
                className="analytics"
                justifyContent="center"
              />
              <Box>
                <Typography
                  mt={2}
                  variant="h4"
                  sx={{
                    fontFamily: 'CustomFont',
                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'center',
                  }}
                >
                  Integrated Analytics Dashboard
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  sx={{
                    fontFamily: 'CustomFont',
                    fontSize: '16px',
                    color: '#8396AC',
                    width: '261px',
                    height: '66px',
                    fontWeight: ' 400',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}
                >
                  Monitor and managing information with ease for informed
                  decision-making.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src="/assets/payment.svg"
                alt=""
                width={85}
                height={77}
                className="payment"
                justifyContent="center"
              />
              <Box>
                <Typography
                  mt={2}
                  variant="h4"
                  sx={{
                    fontFamily: 'CustomFont',
                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'center',
                  }}
                >
                  Seamless Payment Integration
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  sx={{
                    fontFamily: 'CustomFont',

                    fontSize: '16px',
                    color: '#8396AC',
                    width: '261px',
                    height: '66px',
                    fontWeight: ' 400',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}
                >
                  Efficiently manage transactions across the platform with
                  verified payment systems.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src="/assets/growth.svg"
                alt=""
                width={85}
                height={77}
                className="analytics"
                justifyContent="center"
              />
              <Box>
                <Typography
                  mt={2}
                  variant="h4"
                  sx={{
                    fontFamily: 'CustomFont',

                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'center',
                  }}
                >
                  Personalized Customer Engagement
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  sx={{
                    fontFamily: 'CustomFont',

                    fontSize: '16px',
                    color: '#8396AC',
                    width: '261px',
                    height: '66px',
                    fontWeight: ' 400',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}
                >
                  Tailor your communication and promotions using data insights
                  from both platforms to enhance customer satisfaction.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src="/assets/customer.svg"
                alt=""
                width={85}
                height={77}
                className="analytics"
                justifyContent="center"
              />
              <Box>
                <Typography
                  mt={2}
                  variant="h4"
                  sx={{
                    fontFamily: 'CustomFont',

                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'center',
                  }}
                >
                  Scalable Growth Strategies
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  sx={{
                    fontFamily: 'CustomFont',

                    fontSize: '16px',
                    color: '#8396AC',
                    width: '261px',
                    height: '66px',
                    fontWeight: ' 400',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}
                >
                  Access tools and resources for expanding your portfolio with
                  use seamlessly.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
