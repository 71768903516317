import './App.css'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import WhyUs from './components/WhyUs'
import NardHomeBiz from './components/NardHomeBiz'
import Web from './components/Web'
import Mobile from './components/Mobile'
import BannerDesign from './components/BannerDesign'
import OfferedServices from './components/OfferedServices'
import CardDesign from './components/CardDesign'
import NardFxPro from './components/NardFxPro'
import AboutUs from './components/AboutUs'
import Footer from './components/Footer'
import './style/main.scss'

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <WhyUs />
      <NardHomeBiz />
      <NardFxPro />
      <OfferedServices />
      <Web />
      <Mobile />
      <BannerDesign />
      <CardDesign />
      <AboutUs />
      <Footer />
    </>
  )
}

export default App
