import React from 'react'
import { Box, Grid } from '@mui/material'

export default function index() {
  return (
    <Box
      className="footer"
      sx={{ marginTop: '8rem', marginLeft: { xs: '1rem', md: '5rem' } }}
    >
      <Grid container>
        <Grid item md={12} lg={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/assets/company-logo.svg"
              alt=""
              className="company-logo"
              style={{ maxWidth: '50px' }}
            />
            <h3 style={{ marginLeft: '1rem' }}>
              The Nard & Co. Holdings Pte. Ltd.
            </h3>
          </Box>
          <p
            style={{ margin: '1rem 0' }}
            className="grey-text-100 font-size-15 font-responsive"
          >
            Ready to embark your journey with us? Try it today and take your
            first step towards realizing your goals. Join our community and
            unlock a world of possibilities.
          </p>
        </Grid>
        <Grid
          item
          md={12}
          lg={3}
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <h3 className="font-size-18">Discover our app</h3>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '0.8rem',
              gap: '20px',
            }}
          >
            <h5 className="grey-text-100" style={{ flex: 1 }}>
              NardFxPro
            </h5>
            {/* <img
              src="/assets/Footer/line.png"
              alt=""
              style={{ objectFit: 'contain', margin: '0 0rem' }} */}
            {/* /> */}
            {/* <Box
              sx={{
                display: 'flex',

                margin: '0rem 0 0 0',
              }}
            >
              <img
                src="/assets/NardBiz/GooglePlay.svg"
                alt=""
                style={{ display: 'flex' }}
              />
              <img
                src="/assets/NardBiz/PlayStore.svg"
                alt=""
                style={{ marginLeft: '0.3rem', display: 'flex' }}
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              marginTop: '0.6rem',
            }}
          >
            <h5 className="grey-text-100" style={{ flex: 1 }}>
              NardHomeBiz
            </h5>
            {/* <img
              src="/assets/Footer/line.png"
              alt=""
              style={{ objectFit: 'contain', margin: '0 0rem' }} */}
            {/* /> */}
            {/* <Box
              sx={{
                display: 'flex',
                margin: '0rem 0 0.5rem 0',
              }}
            >
              <img src="/assets/NardBiz/GooglePlay.svg" alt="" />
              <img
                src="/assets/NardBiz/PlayStore.svg"
                alt=""
                style={{ marginLeft: '0.2rem' }}
              />
            </Box> */}
          </Box>
        </Grid>
        {/* <Grid item md={12} lg={2} sx={{ marginLeft: { xs: '0', md: '3rem' } }}>
          <h3 className="font-size-18">Social Links</h3>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginTop: '0.3rem' }}
          >
            <img
              src="/assets/Footer/facebook.png"
              alt=""
              style={{ maxWidth: '60px', marginTop: '0.2rem' }}
            />
            <img
              src="/assets/Footer/instagram.png"
              alt=""
              style={{ maxWidth: '40px' }}
            />
          </Box>
        </Grid> */}
      </Grid>
      <h5
        style={{ textAlign: 'center', marginTop: '3rem', marginBottom: '3rem' }}
        className="grey-text-100 font-weight-500"
      >
        All rights The Nard & Co. Holdings Pte. Ltd.
      </h5>
    </Box>
  )
}
