import React, { useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export default function About() {
  const [currentTestimonial, setCurrentTestimonial] = useState(1)
  const [nextTestimonial, setNextTestimonial] = useState(2)

  const testimonials = [
    {
      id: 1,
      title: `“I am happy with NardFxPro performance for the previous year. The investment returns are unexpectedly tremendous. Smart investors will definitely agree with my statement. Highly recommended. ”`,
      name: 'MR. QUEK SJ',
    },
    {
      id: 2,
      title: `“I really like the NardFxPro because it's one of the highest return investment I had so far and the bot gave a satisfying consistent return every month. I've been really enjoying it.”`,
      name: 'Mr. Eddie C',
    },
    {
      id: 3,
      title: `“Thanks to NardFxPro ! I have never been worried about my investment as it generates stable return every month! Hope there are more people benefit from it ! ”`,
      name: 'Ms. Hui Shan C',
    },
    {
      id: 4,
      title: `“I have been using NardFxPro for the past year, and i can confidently say it has revolutionized my approach to Forex Trading. Thanks to NardFxPro, I have achieved consistent returns !”`,
      name: 'Ms. Irene T',
    },
    {
      id: 5,
      title: `“NardFxPro offers the smartest bot assisted trading tool. Genuinely changed my trading experience with time savings and proven results. This is the future and the future is now! ”`,
      name: 'Mr. Chuah SY',
    },
  ]

  const handleTestimonialChange = (direction) => {
    if (direction === 'next') {
      setCurrentTestimonial((prev) => {
        const newCurrent = nextTestimonial
        const newNext =
          nextTestimonial < testimonials.length ? nextTestimonial + 1 : 1
        setNextTestimonial(newNext)
        return newCurrent
      })
    } else if (direction === 'prev') {
      setCurrentTestimonial((prev) => {
        const newCurrent =
          currentTestimonial > 1 ? currentTestimonial - 1 : testimonials.length
        const newNext = currentTestimonial
        setNextTestimonial(newNext)
        return newCurrent
      })
    }
  }

  return (
    <Box className="about" id="testimonials" sx={{ paddingTop: '8rem' }}>
      <Box sx={{ marginLeft: { xs: '2.5rem', md: '7rem' } }}>
        <Grid
          container
          sx={{
            sm: {
              gap: '1rem',
            },
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Testimonials</span>
            <div>
              <h2 className="font-size-45">
                What People Say <br /> About Us
              </h2>
              <img
                className="decore"
                src="/assets/About/Decore.png"
                style={{ paddingBottom: '2rem' }}
                alt=""
              />
            </div>
          </Grid>

          {/* Current Testimonial */}
          <Grid
            item
            xs={10}
            md={5}
            className="main-container"
            sx={{ marginBottom: '6rem', position: 'relative' }}
          >
            <Box
              key={testimonials[currentTestimonial - 1].id}
              id={`testimonial-${testimonials[currentTestimonial - 1].id}`}
              className={'container-review animate-forward'}
              sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                zIndex: '2',
                marginTop: '2rem',
              }}
            >
              {/* {selectedTestimonial === testimonial.id && ( */}

              <div
                style={{
                  backgroundColor: '#ff7c09',
                  borderRadius: '50%',
                  height: '50px',
                  width: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '-25px',
                  left: '-10px',
                }}
              >
                <p style={{ color: 'white', fontWeight: 'bolder' }}>
                  {testimonials[currentTestimonial - 1].name.split(' ')[0][0]}
                  {testimonials[currentTestimonial - 1].name.split(' ')[1][0]}
                </p>
              </div>
              <p style={{ margin: '1rem 0' }} className="grey-text-100">
                {testimonials[currentTestimonial - 1].title}
              </p>
              <h5 className="grey-text-100" style={{ marginTop: '1rem' }}>
                {testimonials[currentTestimonial - 1].name}
              </h5>
            </Box>

            {/* Upcoming Testimonial at the Bottom */}
            <Box
              key={testimonials[nextTestimonial - 1].id}
              id={`testimonial-${testimonials[nextTestimonial - 1].id}`}
              className={'container-review-light animate-backward'}
              sx={{ marginTop: '2rem' }}
            >
              <p style={{ margin: '1rem 0' }} className="grey-text-100">
                {testimonials[nextTestimonial - 1].title}
              </p>
              <h5 className="grey-text-100" style={{ marginTop: '1rem' }}>
                {testimonials[nextTestimonial - 1].name}
              </h5>
            </Box>
          </Grid>

          <Grid item xs={1} md={1}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => handleTestimonialChange('prev')}
                disabled={currentTestimonial === 1}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
              <IconButton
                sx={{ marginTop: '0.7rem' }}
                onClick={() => handleTestimonialChange('next')}
                disabled={currentTestimonial === testimonials.length}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
